.App,
#root {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.App {
  height: 400px;
  width: 700;
}

#root {
  height: 100vh;
  width: 100vw;
}

.error {
  text-align: center;
  color: red;
  font-size: larger;
}

.form-group {
  margin: 10px;
  padding: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
}

.form-label {
  margin: 5px;
}

.output-address {
  color: green;
}
